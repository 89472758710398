@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .link {
    @apply underline text-brand-300 hover:text-brand-100 cursor-pointer;
    font-weight: inherit;
  }
}

@layer base {
  body {
    @apply font-body;
  }

  [id] {
    scroll-margin-top: 8rem;
  }

  .text-headline {
    @apply font-title text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold;
  }

  .text-heading-1 {
    @apply font-title text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold;
  }

  .text-heading-2 {
    @apply font-title text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold;
  }

  .text-heading-3 {
    @apply font-title text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold;
  }

  .text-heading-4 {
    @apply font-title text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium;
  }

  .text-heading-5 {
    @apply font-title text-base sm:text-lg md:text-xl lg:text-2xl font-medium;
  }

  .text-heading-6 {
    @apply font-title text-base sm:text-base md:text-lg lg:text-xl font-medium;
  }

  .text-lede {
    @apply font-body text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl;
  }

  .text-eyebrow {
    @apply font-pixel text-xs md:text-sm xl:text-base;
  }

  .text-code-2xs {
    @apply font-code text-[7.5px] leading-[12px] tracking-[0px];
  }

  .text-code-xs {
    @apply font-code text-[10px] leading-[16px] tracking-[0px];
  }

  .text-code-sm {
    @apply font-code text-[12.5px] leading-[20px] tracking-[0px];
  }

  .text-code-base {
    @apply font-code text-[15px] leading-[24px] tracking-[0px];
  }

  .text-code-lg {
    @apply font-code text-[20px] leading-[32px] tracking-[0px];
  }

  .text-code-xl {
    @apply font-code text-[25px] leading-[36px] tracking-[0px];
  }

  .text-code-2xl {
    @apply font-code text-[30px] leading-[40px] tracking-[0px];
  }

  .text-code-3xl {
    @apply font-code text-[40px] leading-[48px] tracking-[0px];
  }

  .text-code-4xl {
    @apply font-code text-[50px] leading-[56px] tracking-[0px];
  }

  .text-code-5xl {
    @apply font-code text-[60px] leading-[64px] tracking-[0px];
  }

  .text-code-6xl {
    @apply font-code text-[70px] leading-[72px] tracking-[0px];
  }

  .text-code-7xl {
    @apply font-code text-[80px] leading-[80px] tracking-[0px];
  }

  .text-code-8xl {
    @apply font-code text-[90px] leading-[88px] tracking-[0px];
  }

  .text-code-9xl {
    @apply font-code text-[100px] leading-[96px] tracking-[0px];
  }

  .text-1 {
    @apply text-light-text-1 dark:text-dark-text-1;
  }

  .text-2 {
    @apply text-light-text-2 dark:text-dark-text-2;
  }

  .text-3 {
    @apply text-light-text-3 dark:text-dark-text-3;
  }

  .text-4 {
    @apply text-light-text-4 dark:text-dark-text-4;
  }

  .text-5 {
    @apply text-light-text-5 dark:text-dark-text-5;
  }

  .text-block {
    @apply text-2 space-y-4 md:space-y-6 text-base md:text-lg xl:text-xl;
  }

  .text-block h1 {
    @apply text-headline font-bold text-1;
  }

  .text-block h1 + p {
    @apply text-lede text-3 pb-4 md:pb-8 xl:pb-12 !mt-2;
  }

  .text-block h2 {
    @apply text-2xl md:text-3xl xl:text-4xl font-bold text-1 font-title;
  }

  .text-block h3 {
    @apply text-lg md:text-xl xl:text-2xl font-medium text-1;
  }

  .text-block h4 {
    @apply font-bold text-1;
  }

  .text-block h5,
  .text-block h6 {
    @apply font-medium text-1;
  }

  .text-block h2:not(:where(hr + *)),
  .text-block h3:not(:where(hr + *)),
  .text-block h4:not(:where(hr + *)),
  .text-block h5:not(:where(hr + *)),
  .text-block h6:not(:where(hr + *)) {
    @apply pt-4 xl:pt-6;
  }

  .text-block hr {
    @apply max-w-screen-md !my-8 xl:!my-12 border-0 border-t border-brand-purple/20 dark:border-brand-purple/20 clear-both;
  }

  .text-block strong {
    font-weight: 700;
    color: inherit;
  }

  .text-block p a,
  .text-block li a {
    @apply underline text-brand-300 hover:text-brand-100 cursor-pointer;
    font-weight: inherit;
  }

  .text-block h1 a,
  .text-block h2 a,
  .text-block h3 a,
  .text-block h4 a,
  .text-block h5 a,
  .text-block h6 a {
    @apply no-underline opacity-100 hover:opacity-90 cursor-pointer;
  }

  .text-block ol,
  .text-block ul {
    @apply space-y-2 md:space-y-3 list-[square] list-outside pl-4;
  }

  .text-block ol {
    @apply list-[decimal];
  }

  .text-block li {
    @apply relative md:pl-2;
  }

  .text-block li::marker {
    @apply text-dark-text-5;
  }

  .text-block li p + p {
    @apply text-3;
  }

  .text-block li strong + em {
    @apply inline-block ml-1 text-brand-blue/80 not-italic;
    font-size: 0.67em;
  }

  .text-block .footnotes {
    @apply text-4 text-sm xl:text-base;
  }

  .text-block .footnotes ol li[id]::marker {
    --tw-text-opacity: 0.5;
    @apply text-4 text-xs xl:text-sm font-normal;
  }

  .text-block .footnotes .data-footnote-backref {
    @apply no-underline;
  }

  .guide h1,
  .guide h2,
  .guide h3,
  .guide h4,
  .guide h5,
  .guide h6,
  .guide p,
  .guide ol,
  .guide ul {
    @apply max-w-screen-md;
  }

  .guide .code-block {
    @apply max-w-screen-md;
  }

  .guide img,
  .guide video {
    @apply w-full max-w-screen-md my-4 md:my-6;
  }

  /* Sandbox overrides */
  .sandbox .sp-layout {
    @apply bg-transparent gap-6 rounded-none border-none overflow-visible;
  }

  .sandbox .sp-editor,
  .sandbox .sp-stack {
    @apply rounded-2xl overflow-hidden shadow-[0_4px_32px_0px] shadow-brand-purple/10;
  }

  .sandbox .sp-editor [data-active="true"] {
    @apply text-1;
  }

  .sandbox .cm-gutter.cm-lineNumbers {
    font-size: 0.75rem !important;
  }

  .sandbox .sp-button {
    @apply flex-row-reverse !px-4 !bg-brand-500 !text-1 hover:!bg-brand-purple font-pixel uppercase;
  }
  .sandbox .sp-button > span::after {
    content: " Code";
  }
  .sandbox .sp-button > svg {
    display: none;
  }

  .sandbox .sp-preview-container {
    @apply bg-dark-100 text-1;
  }

  .sandbox .sp-console {
    @apply bg-black px-2 !font-pixel !text-brand-green/80;
  }

  .sandbox .sp-console-list:empty::before {
    content: '↑ You can edit the code block above ↑, then hit the "RUN CODE" button to see the output here';
    @apply block p-4 text-xs leading-5 !text-brand-green/60;
  }
  @media (min-width: 768px) {
    .sandbox .sp-console-list:empty::before {
      content: '<-- You can edit the code block on the left, then hit the "RUN CODE" button to see the output here.';
    }
  }

  .sandbox .sp-console-item code {
    @apply text-brand-green/80 !text-xs !leading-5 !font-pixel;
  }

  .sandbox .sp-preview,
  .sandbox .sp-preview-actions,
  .sandbox .sp-resize-handler,
  .sandbox .sp-console-header,
  .sandbox .sp-console-actions {
    display: none !important;
  }

  .sandbox .sp-console {
    background: black
      radial-gradient(
        ellipse 80% 60% at center,
        rgb(102 217 239 / 0.2) 0%,
        rgb(102 217 239 / 0.12) 75%,
        rgb(102 217 239 / 0.08) 100%
      ) !important;
    border: 1px solid rgb(174 129 255 / 0.12);
  }
  @keyframes scanlines {
    0% {
      background-position: 0 0;
      opacity: 0.16;
    }
    50% {
      background-position: 0 -16px;
      opacity: 0.08;
    }
    100% {
      background-position: 0 -32px;
      opacity: 0.16;
    }
  }

  .sandbox .sp-console::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: repeating-linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0px,
      rgba(0, 0, 0, 1) 4px,
      transparent 4px,
      transparent 8px
    );
    pointer-events: none;
    animation: scanlines 4s linear infinite;
  }

  .sandbox .sp-console-item {
    @apply py-8;
  }
  .sandbox .sp-console-item:not(:first-child)::after {
    @apply h-1.5 ml-2 w-[160px] bg-transparent border-t border-b border-brand-green/50;
  }

  .sandbox .sp-console-item code *::selection {
    @apply bg-black/50;
  }

  .sandbox .sp-console-item code *::-moz-selection {
    @apply bg-black/50;
  }
}

@layer components {
  .text-block :where(code):not(:where([class~="not-text-block"] *)) {
    @apply bg-black inline-block font-code text-[0.8em] text-brand-200 font-normal px-1 rounded-md border border-dark-500;
  }
  .text-block p a :where(code):not(:where([class~="not-text-block"] *)),
  .text-block li a :where(code):not(:where([class~="not-text-block"] *)) {
    @apply underline hover:text-brand-100 cursor-pointer;
  }

  .text-block :where(pre):not(:where([class~="not-text-block"] *)) {
    @apply font-code font-normal mt-0 ml-0 mr-0 mb-0;
  }

  .text-block :where(code):not(:where([class~="not-text-block"] *))::before,
  .text-block :where(code):not(:where([class~="not-text-block"] *))::after {
    content: "" !important;
  }

  .fade-in {
    opacity: 0;
    transform: scale(0.96);
    transition:
      opacity 0.4s ease-out,
      transform 0.4s ease-out;
  }

  .fade-in.visible {
    opacity: 1;
    transform: scale(1);
  }
}

/* Overrides */
code,
kbd,
samp,
pre {
  font-family: "Red Hat Mono", monospace;
}

code {
  padding: 0;
  min-width: unset;
  white-space: pre;
}

pre {
  padding: 0;
  min-width: unset;
  max-width: 100%;
  overflow: auto;
  white-space: pre;
}

code.wrap,
pre.wrap {
  white-space: pre-wrap;
  overflow-y: hidden;
  overflow-wrap: anywhere;
}
